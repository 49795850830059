import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/meta/seo';

const Home = () => (
  <Layout>
    <SEO title="Not Found" />
    <div className="container space-around">
      <h1>Not Found</h1>
    </div>
  </Layout>
);

export default Home;
